<template>
    <!--展览-->
    <div>
        <div class="plateBox srhBox flex_l_c">
            <input type="text" class="mainBdColor srhIpt" placeholder="请输入展览名称" v-model="srhStr">
            <button class="srhBtn mainBdColor mainBgColor" @click="refreshList(1)"><i class="el-icon-search"></i>搜索</button>
        </div>
        <div class="plateBox">
            <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选查询</span></div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">展览类型：</div>
                    <div :class="['flex1', unfoldExhibitionFlag ? 'exhibitionTagBoxUnfold' : 'exhibitionTagBox']">
                        <div :class="['exhibitionTagItem', exhibitionIndex == -1 ? 'mainBgColor whiteColor': 'resetColor']"
                             @click="selExhibition(item, -1)">
                            {{item.label}}
                        </div>
                        <div :class="['exhibitionTagItem', exhibitionIndex == index ? 'mainBgColor whiteColor': 'resetColor']"
                             v-for="(item1, index) in $dictUtils.getDictList('exhibition_type')"
                                    :label="item1.label"
                                    :value="item1.value" :key="item1.value" @click="selExhibition(item1, index)">
                            {{item1.label}}
                        </div>
                    </div>
                </div>
                <div class="mainBdColor mainColor unfoldBtn pointer" @click="unfoldExhibition">
                    <span>{{unfoldExhibitionFlag ? '收起': '展开'}}</span>
                    <span :class="[unfoldExhibitionFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">已选条件：</div>
                    <div class="flex1">
                        <el-tag effect="plain" closable v-for="(item, index) in conditionsArr" :key="index"
                                @close="closeConditions(index)">{{item.name}}
                        </el-tag>
                    </div>
                </div>
                <div class="flex_r_c">
                    <div class="clearAllBtn pointer" @click="clearAll">清除全部</div>
                </div>
            </div>
        </div>
        <div class="plateBox">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选结果/操作</span></div>
                <el-button-group>
                    <el-button size="small" :type="showList ? '' : 'primary'" @click="showList = 0">卡片</el-button>
                    <el-button size="small" :type="showList ? 'primary' : ''" @click="showList = 1">列表</el-button>
                </el-button-group>
            </div>
            <div class="noData" v-if="exhibitionListHome.length == 0 && !showList">暂无数据</div>
            <!--卡片-->
            <div v-show="!showList">
                <div class="exhibitionItem pointer" v-for="(item, index) in exhibitionListHome" :key="index" @click="view(item)">
                    <div class="h100">
                        <div>
                            <div class="titleBox">
                                <el-tooltip effect="dark" :content="item.exhibitionName" placement="top">
                                    <div class="title omit">
                                        <span class="numberBox mainColor" v-show="item.exhibitionId">
                                            <span class="numberBg mainBgColor mainBgTColor"></span>
                                            <span>{{item.exhibitionId}}</span>
                                        </span>
                                        <span>{{item.exhibitionName}}</span>
                                    </div>
                                </el-tooltip>
                                <!-- <div class="privacy">{{item.efieldName10}}</div> -->
                            </div>
                            <!--<div class="tagBox">
                                <el-tooltip effect="dark" :content="item.efieldName13" placement="top" :disabled="!item.efieldName13">
                                    <div :class="['tagItem mainColor omit', item.efieldName13 ? 'mainBdColor':'']">
                                        <span>{{item.efieldName13}}</span>
                                    </div>
                                </el-tooltip>
                            </div>-->
                            <div class="infoBox">
                                <div class="item omit" v-if="item.efieldName15">
                                    <span class="field">主办方：</span>
                                    <span>{{item.efieldName15}}</span>
                                </div>
                                <div class="item omit" v-if="item.exhibitionUserName">
                                    <span class="field">策展人：</span>
                                    <span>{{item.exhibitionUserName}}</span>
                                </div>
                                <div class="item omit" v-if="item.exhibitionTypeValue">
                                    <span class="field">展览类型：</span>
                                    <span :prop="item.exhibitionTypeValue"
                                          v-for="item1 in $dictUtils.getDictList('exhibition_type')"
                                          :key="item1.value"
                                          :label="item1.label"
                                          :value="item1.value">
                                        <span>{{item1.value==item.exhibitionTypeValue?item1.label:""}}</span>
                                    </span>
                                </div>
                                <div class="item omit" v-if="item.beginDatetime">
                                    <span class="field">展览时间：</span>
                                    <el-tooltip effect="dark" :content="`${item.beginDatetime}～${item.endDatetime}`"
                                                placement="top">
                                        <span>{{item.beginDatetime}}～{{item.endDatetime}}</span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="flex_b_c bottomInfo">
                            <div class="flieBox mainColor">
                                <span class="iconfont icon-wendang"></span>{{item.exhibitionTotal ? item.exhibitionTotal : 0}}
                            </div>
                            <div class="text_right">
                                <el-button type="primary" plain v-show="item1.linkUrl" v-for="(item1, index1) in item.sysExhibitionLinks" :key="index1" size="small" @click="webpage(item1)">{{item1.linkName}}</el-button>

                                <!--<div :class="['btnItem', index1%2 == 0 ? 'mainBgColor' : '', item1.linkUrl? 'mainBgColor': 'disBtnItem']"
                                     v-for="(item1, index1) in item.sysExhibitionLinks" :key="index1"
                                     @click.stop="item1.linkUrl && webpage(item1)">
                                    {{item1.linkName}}
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--列表-->
            <el-table
                    v-show="showList"
                    :data="exhibitionList"
                    class="table" ref="tableBox" @sort-change="sortChange">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column prop="exhibitionId" label="展览编号" width="150" sortable="custom" show-overflow-tooltip></el-table-column>
                <el-table-column prop="exhibitionName" label="展览名称" min-width="180" sortable="custom" show-overflow-tooltip></el-table-column>
                <el-table-column prop="exhibitionTypeValue" label="展览类型" width="150" sortable="custom" show-overflow-tooltip>
                    <template slot-scope="scope">
                       {{ $dictUtils.getDictLabel("exhibition_type", scope.row.exhibitionTypeValue) }}
                   </template>
                </el-table-column>
                <el-table-column prop="exhibitionUserName" label="策展人" width="150" sortable="custom" show-overflow-tooltip></el-table-column>
                <el-table-column prop="beginDatetime" label="展览开始时间" min-width="180" sortable="custom" show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.beginDatetime">{{common.formatDate(scope.row.beginDatetime)}}</template>
                </el-table-column>
                <el-table-column prop="endDatetime" label="展览结束时间" min-width="180" sortable="custom" show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.endDatetime">{{common.formatDate(scope.row.endDatetime)}}</template>
                </el-table-column>
                <el-table-column prop="exhibitionPlaceValue" label="展览地点" width="150" sortable="custom" show-overflow-tooltip>
<!--                    <template slot-scope="scope">-->
<!--                        {{ $dictUtils.getDictLabel("exhibition_place", scope.row.exhibitionPlaceValue, '-') }}-->
<!--                    </template>-->
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text"
                        v-for="(item1, index1) in scope.row.sysExhibitionLinks" v-if="item1.linkUrl!==''" :key="index1"  @click.stop="webpage(item1)">
                            {{item1.linkName}}</el-button>
                        <el-button type="text" @click="view(scope.row)">查看展览</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           :current-page="pageNo"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size="pageSize"
                           :total="total"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--高级检索-->
        <AdvancedQuery ref="advancedQuery" @getDataList="searchGetDataList"></AdvancedQuery>
    </div>
</template>

<script>
    import AdvancedQuery from '../search/advancedQuery' // 高级查询弹窗

    export default {
        components: {AdvancedQuery},
        props:['homeSrh'],
        data() {
            return {
                srhStr: '',
                exhibitionIndex: -1,
                unfoldExhibitionFlag: false,
                conditionsArr: [],
                showList: 1,
                exhibitionList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                sortField: null,
                sortOrder: null,
                config: [],
                exhibitionTypeValue:'',
                item:{
                    label:"全部",
                    value:-1
                },
                exhibitionListHome: [],
                pageNoHome: 1,
                pageSizeHome: 10,
                totalHome: 0,
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
            },
            homeSrh(val) {
                this.srhStr = val;
                this.refreshList();
            },
            srhStr(val) {
                this.$emit('changeSrh',val)
            },
        },
        created() {
            let type = sessionStorage.getItem('exhibitionShowList')
            let srhData = JSON.parse(sessionStorage.getItem('exhibitionSrh'))
            if(type != null && type && this.$route.query.type == 1) {
                this.showList = parseInt(type)
            }
            if(srhData != null && srhData && this.$route.query.type == 1) {
                this.conditionsArr = srhData.conditionsArr;
                this.srhStr = srhData.srhStr;
                this.exhibitionIndex = srhData.exhibitionIndex;
                this.pageNo = parseInt(srhData.pageNo);
            } else {
                this.srhStr = this.homeSrh;
                this.pageNo = 1;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
            this.refreshList();
            this.refreshListHome();
        },
        methods: {
            // 排序
            sortChange({column, prop, order}) {
                if(prop==="exhibitionId"){
                    prop = "exhibition_id"
                }
                if(prop==="exhibitionName"){
                    prop = "exhibition_name"
                }
                if(prop==="exhibitionUserName"){
                    prop = "exhibition_user_name"
                }
                if(prop==="beginDatetime"){
                    prop = "begin_datetime"
                }
                if(prop==="endDatetime"){
                    prop = "end_datetime"
                }
                if(prop==="exhibitionPlaceValue"){
                    prop = "exhibition_place_value"
                }
                this.sortField = prop;
                this.sortOrder = order;
                this.refreshList();
            },
            // 获取数据列表
            refreshList(type) {
                if(type == 1) {
                    this.pageNo = 1;
                }
                this.loading = true
                this.$axios(this.api.zlzs.informationByCard1, {
                    'exhibitionName': this.srhStr,
                    'exhibitionTypeValue':this.exhibitionTypeValue,
                    'exhibitionFlag':true,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'sortField': this.sortField,
                    'sortOrder': this.sortOrder,
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.exhibitionList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.$nextTick(() => {
                            this.changeMainColor(this.defaultTheme);
                        })
                    }
                })
            },
            // 获取数据列表
            refreshListHome() {
                this.loading = true
                this.$axios(this.api.zlzs.informationByCard1, {
                    'exhibitionName': this.srhStr,
                    'exhibitionTypeValue':this.exhibitionTypeValue,
                    'exhibitionFlag':true,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'post').then(data => {
                    console.log(data);
                    if (data && data.status) {
                        this.exhibitionListHome = data.data.records
                        this.$nextTick(() => {
                            this.changeMainColor(this.defaultTheme);
                        })
                    }
                })
            },
            // 选择展览
            selExhibition(item, index) {
                this.exhibitionIndex = index;
                this.pageNo = 1;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.conditionsArr[0] ={
                    name: item.label
                }
                this.exhibitionTypeValue=item.value
                if(item.value<0){
                    this.exhibitionTypeValue=''
                }

                this.refreshList()
            },
            // 展览展开
            unfoldExhibition() {
                this.unfoldExhibitionFlag = !this.unfoldExhibitionFlag
            },
            // 移除已选条件
            closeConditions(index) {
                this.conditionsArr.splice(index, 1)
                this.exhibitionIndex = -1;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.exhibitionTypeValue=''
                this.refreshList()
            },
            // 清除全部
            clearAll() {
                this.exhibitionIndex = -1;
                this.conditionsArr = [];
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.exhibitionTypeValue=''
                this.refreshList()
            },
            // 增加筛选(高级查询)
            advancedQuery() {
                this.$refs.advancedQuery.init(this.config)
            },
            // 获取增加筛选(高级查询)数据
            searchGetDataList(data) {
            },
            // 展览网页
            webpage(row) {
                window.open(row.linkUrl);
            },
            // 数字展厅
            hall(row) {

            },
            // 查看展览
            view(row) {
                let srh = {
                    conditionsArr: this.conditionsArr,
                    srhStr: this.srhStr,
                    exhibitionIndex: this.exhibitionIndex,
                    pageNo: this.pageNo,
                }
                sessionStorage.setItem('exhibitionShowList', this.showList)
                sessionStorage.setItem('exhibitionSrh', JSON.stringify(srh))
                this.$router.push({path: '/zlzs/retrieval/exhibitionDetail', query: { id: row.id, type: 1, val:this.$route.query.val }});
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                if(this.showList){
                    this.refreshList()
                }else{
                    this.refreshListHome()
                }

            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                if(this.showList){
                    this.refreshList()
                }else{
                    this.refreshListHome()
                }

            },
        }
    }
</script>

<style scoped>

</style>
