<template>
    <!--文物-->
    <div>
        <div class="plateBox srhBox flex_l_c">
            <input type="text" class="mainBdColor srhIpt" placeholder="请输入档号/文物名称/时代/尺寸/收藏地点/出土地点/展览名称" v-model="srhStr">
            <button class="srhBtn mainBdColor mainBgColor" @click="search"><i class="el-icon-search"></i>搜索</button>
        </div>
        <div class="plateBox">
            <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选查询</span></div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">展览名称：</div>
                    <div :class="['flex1', unfoldExhibitionFlag ? 'exhibitionTagBoxUnfold' : 'exhibitionTagBox']">
                        <div :class="['exhibitionTagItem', exhibitionVal.id == item.id ? 'mainBgColor whiteColor': 'resetColor']"
                             v-for="(item, index) in exhibitionTag" :key="index" @click="selExhibition(item, index)">
                            {{item.exhibitionName}}
                        </div>
                    </div>
                </div>
                <div class="mainBdColor mainColor unfoldBtn pointer" @click="unfoldExhibition">
                    <span>{{unfoldExhibitionFlag ? '收起': '展开'}}</span>
                    <span :class="[unfoldExhibitionFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div>
            <!-- <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">时代：</div>
                    <div :class="[unfoldYearFlag ? 'exhibitionTagBoxUnfold' : 'exhibitionTagBox']">
                        <div :class="['exhibitionTagItem', yearIndex == index ? 'mainBgColor whiteColor': 'resetColor']"
                             v-for="(item, index) in yearTag" :key="index" @click="selYear(item, index)">
                            {{item.exhibitionName}}
                        </div>
                    </div>
                </div>
                <div class="mainBdColor mainColor unfoldBtn pointer" @click="unfoldYear">
                    <span>{{unfoldYearFlag ? '收起': '展开'}}</span>
                    <span :class="[unfoldYearFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div> -->
            <div class="flex_b_t queryItem" v-show="upAllFlag">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">标签：</div>
                    <div :class="['flex1', unfoldLabelFlag ? 'exhibitionTagBoxUnfold' : 'exhibitionTagBox']">
                        <div :class="['exhibitionTagItem', labelVal.id == item.id ? 'mainBgColor whiteColor': 'resetColor']"
                             v-for="(item, index) in labelTag" :key="index" @click="selLabel(item, index)">
                            {{item.name}}
                        </div>
                    </div>
                </div>
                <div class="mainBdColor mainColor unfoldBtn pointer" @click="unfoldLabel">
                    <span>{{unfoldLabelFlag ? '收起': '展开'}}</span>
                    <span :class="[unfoldLabelFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">已选条件：</div>
                    <div class="flex1">
                        <el-tag effect="plain" closable @close="clearExhibition(0)"
                                v-if="exhibitionVal.exhibitionName">{{exhibitionVal.exhibitionName}}
                        </el-tag>
                        <el-tag effect="plain" closable @close="clearFiltrate(index)" v-for="(item, index) in advanced" :key="index">{{item.recordName}}{{item.conditionName}}{{item.condition}}</el-tag>
                        <el-tag effect="plain" closable @close="clearExhibition(1)"
                                v-if="labelVal.name">{{labelVal.name}}
                        </el-tag>
                        <el-tag class="addFiltrate pointer" @click="advancedQuery">增加筛选</el-tag>
                    </div>
                </div>
                <div class="clearAllBtn pointer" @click="clearAll">清除全部</div>
            </div>
        </div>
        <div class="plateBox">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选结果/操作</span></div>
                <div>
                    <el-button size="small" v-show="showList" type="primary" plain @click="download()"
                               :disabled="dataListSelections.length==0">下载
                    </el-button>
                    <!-- <el-button v-show="showList" type="primary" plain @click="collect()"
                               :disabled="dataListSelections.length==0">收藏
                    </el-button>
                    <el-button v-show="showList" type="primary" plain @click="borrow()"
                               :disabled="dataListSelections.length==0">借阅
                    </el-button> -->
                    <el-button-group class="m_l1">
                        <el-button size="small" :type="showList ? '' : 'primary'" @click="showList = false">卡片</el-button>
                        <el-button size="small" :type="showList ? 'primary' : ''" @click="showList = true">列表</el-button>
                    </el-button-group>
                </div>
            </div>
            <div class="noData" v-if="list.length == 0 && !showList">暂无数据</div>
            <!--卡片-->
            <div class="listBox" v-show="!showList && list.length > 0">
                <div class="item pointer" v-for="(item, index) in list" :key="index" @click="view(item)">
                    <el-image fit="cover" :src="item.url" class="itemImg">
                        <el-image slot="placeholder" fit="cover" class="itemImg" :src="require('@/assets/img/thumbnail.png')"></el-image>
                        <el-image slot="error" fit="cover" class="itemImg" :src="require('@/assets/img/thumbnail.png')"></el-image>
                    </el-image>
                    <div class="info">
                        <el-tooltip effect="dark" :content="item.culturalName" placement="top">
                            <div class="name omit">{{item.culturalName}}</div>
                        </el-tooltip>
                        <div class="flex_b_t">
                            <el-tooltip effect="dark" :content="item.times" placement="top" :disabled="!item.times">
                                <div class="age omit" v-show="item.times">{{item.times}}</div>
                            </el-tooltip>
                            <el-tooltip effect="dark" :content="item.size" placement="top" :disabled="!item.size">
                                <div class="age omit" v-show="item.size">{{item.size}}</div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <!--列表-->
            <el-table v-show="showList"
                    :data="list" v-loading="loading" @selection-change="selectionChangeHandle"
                    class="table" ref="tableBox">
                <el-table-column type="selection" width="50" fixed/>
                <el-table-column v-for="(item,index) in config" :key="item.id" :prop="item.fieldEnname"
                                 :label="item.recordName"
                                 :show-overflow-tooltip="item.fieldEnname != 'url'" v-if="item.onShow==0" min-width="120">
                    <template slot-scope="scope">
                        <el-input-number v-if="item.fieldEnname=='sequenceNumber'" style="width: 100px" size="mini"
                                         v-model="scope.row.sequenceNumber" @change="handleChangeOrder(scope.row)"
                                         :min="1"></el-input-number>
                        <el-image v-else-if="item.fieldEnname=='url'"
                                  style="width: 100px; height: 100px"
                                  :src="scope.row.url"
                                  fit="contain"></el-image>
                        <div v-else v-show="scope.row[item.fieldEnname]">{{scope.row[item.fieldEnname]}}</div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="view(scope.row)">查看</el-button>
                        <el-button type="text" @click="download(scope.row,1)">下载</el-button>
                        <!-- <el-button type="text" @click="collect(scope.row.id,1)">收藏</el-button>
                        <el-button type="text" @click="borrow(scope.row,1)">借阅</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           :current-page="pageNo"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size="pageSize"
                           :total="total"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--高级检索-->
        <AdvancedQuery ref="advancedQuery" @getDataList="searchGetDataList"></AdvancedQuery>
        <!--借阅-->
        <BorrowForm ref="borrowForm"></BorrowForm>
        <!--查看-->
        <DetailPage ref="detailPage"></DetailPage>
    </div>
</template>

<script>
    import AdvancedQuery from '../search/advancedQuery' // 高级查询弹窗
    import BorrowForm from '../search/BorrowForm' // 借阅弹窗
    import DetailPage from '../search/detailPage' // 查看弹窗

    export default {
        components: {BorrowForm, AdvancedQuery, DetailPage},
        props:['homeSrh'],
        data() {
            return {
                srhStr: '',
                // 展览
                unfoldExhibitionFlag: false,
                exhibitionIndex: 0,
                exhibitionVal: {
                    id: '',
                    exhibitionName: ''
                },
                exhibitionTag: [
                    {
                        id: '',
                        exhibitionName: '全部'
                    }
                ],
                // 标签
                upAllFlag: true, // 全部展开
                unfoldLabelFlag: false,
                labelIndex: 0,
                labelVal: {name: '', id: ''},
                labelTag: [{name:'全部',id:''}],

                // 时代
                unfoldYearFlag: false,
                yearIndex: 0,
                yearVal: {},
                yearTag: [],
                loading: false,
                showList: true,
                list: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                config: [],
                senior:false,
                dateList:[],
                // 增加筛选
                advanced: [],
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
            },
            homeSrh(val) {
                this.srhStr = val;
                this.getCatalogue();
                this.querySysOriginalDescriptionAll();
            },
            srhStr(val) {
                this.$emit('changeSrh',val)
            },
        },
        created() {
            this.srhStr=this.homeSrh;
        },
        mounted() {
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
            this.getCatalogue();
            this.querySysOriginalDescriptionAll();
        },
        methods: {
            // 初始化
            init(srhStr) {
                this.search();
            },
            // 查询展览
            getCatalogue() {
                // this.exhibitionVal = {}
                this.$axios(this.api.zlzs.queryExhibitionRecord, {}, 'get').then(data => {
                    if (data && data.status) {
                        this.exhibitionTag=data.data
                        this.exhibitionTag.unshift({exhibitionName:'全部',id:''})
                        //this.tableData()
                        this.getDataList(this.advanced);;
                    }
                })
            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
            querySysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
                    flag: 5,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'exhibitionId') {
                                v.fieldEnname = 'exhibitionName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                        this.search();
                    }
                })
            },
            //文件标签
            labelIndexQueryAll() {
                // this.labelVal = {}
                this.$axios(this.api.zlzs.labelIndexList, {
                    "name": '',
                    "state":1,
                    'pageNo': 1,
                    'pageSize': 9,
                }, 'get').then(data => {
                    if (data.status && data.data) {
                        this.labelTag = data.data.records
                        this.labelTag.unshift({name: '全部', id: ''})
                    }
                })
            },
            // 检索
            tableData() {
                // if(this.catalogueShuju || this.biaoSelect){
                this.loading = true
                this.$axios(this.api.zlzs.culturalRelicsRetrieve, {
                    "name": this.srhStr,
                    "exhibitionId": this.exhibitionVal.id,
                    "labelId": '',
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 5,
                    'onDetails': 1,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.list = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.senior = false
                        this.$refs.tableBox.doLayout();
                    }
                })
                // }

            },
            // 搜索
            search() {
                this.pageNo = 1
                this.type = 0
                // this.tableData()
                this.labelIndexQueryAll();
                this.getDataList(this.advanced);
            },
            // 增加筛选(高级查询)
            advancedQuery() {
                let config = JSON.parse(JSON.stringify(this.config))
                config.forEach(function (v,i) {
                    if(v.recordName=="文物照片"){
                        config.splice(i,1)
                    }
                })
                this.$refs.advancedQuery.init(config)
            },
            // 获取增加筛选(高级查询)数据
            searchGetDataList(data) {
                this.type = 1
                this.pageNo = 1
                this.dateList = data
                this.getDataList(data);
            },
            toUp(str) {
                let newStr = ''
                let arr = str.split('_') //先用字符串分割成数组
                arr.forEach((item, index) => {
                    if (index > 0) {
                        return newStr += item.replace(item[0], item[0].toUpperCase())
                    } else {
                        return newStr += item
                    }
                })
                return newStr
            },
            //高级查询检索返回
            getDataList(data) {
                this.advanced = JSON.parse(JSON.stringify(data));
                let dict = this.$dictUtils.getDictList('senior_retrieve')
                this.advanced.forEach(v => {
                    this.$set(v, 'eName', this.toUp(v.fieldName))
                })
                this.advanced.map(item => {
                    this.config.map(itemC => {
                        if(item.eName == itemC.fieldEnname) {
                            this.$set(item, 'recordName', itemC.recordName)
                        }
                    })
                    dict.map(itemD => {
                        if(item.equalOpt == itemD.value) {
                            this.$set(item, 'conditionName', itemD.label)
                        }
                    })
                })
                this.loading = true
                this.$axios(this.api.zlzs.culturalRelicsSeniorQuery, {
                    "name": this.srhStr,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 5,
                    'onDetails': 1,
                    "seniorQueries": data,
                    "exhibitionId": this.exhibitionVal.id,
                    "labelId": this.labelVal.id,
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.list = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.senior = true
                        this.$refs.tableBox.doLayout();
                    }
                })
            },
            // 选择展览
            selExhibition(item, index) {
                this.exhibitionIndex = index;
                this.exhibitionVal = item;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                //this.tableData()
                this.getDataList(this.advanced);
            },
            // 选择标签
            selLabel(item, index) {
                this.labelIndex = index;
                this.labelVal = item;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 展览展开收起
            unfoldExhibition() {
                this.unfoldExhibitionFlag = !this.unfoldExhibitionFlag
            },
            // 标签展开收起
            unfoldYear() {
                this.unfoldYearFlag = !this.unfoldYearFlag
            },
            // 标签展开收起
            unfoldLabel() {
                this.unfoldLabelFlag = !this.unfoldLabelFlag
            },
            // 清除展览筛选
            clearExhibition(type) {
                if(type == 0){
                    this.exhibitionIndex = 0;
                    this.exhibitionVal = {
                        id: '',
                        exhibitionName: ''
                    };
                }else {
                    this.labelVal = {name: '', id: ''};
                }
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // if(this.advanced.length>=0){
                //     this.getDataList(this.dateList);
                // }else{
                    // this.tableData()
                    this.getDataList(this.advanced);
                // }
            },
            // 清除新增筛选
            clearFiltrate(index) {
                this.advanced.splice(index, 1);
                let data = JSON.parse(JSON.stringify(this.advanced));
                data.map(v => {
                    this.$delete(v,'eName')
                    this.$delete(v,'recordName')
                    this.$delete(v,'conditionName')
                })
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 清除全部
            clearAll() {
                this.exhibitionIndex = 0;
                this.yearIndex = 0;
                this.labelIndex = 0;
                this.exhibitionVal = {
                    id: '',
                    exhibitionName: ''
                };
                this.yearVal = {};
                this.labelVal = {name: '', id: ''};
                this.advanced = [];
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 收藏
            collect(id,val) {
                let ids =val==1? id : this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$axios(this.api.zlzs.MyCollectionSave, {
                    fileIds: ids
                }, 'post').then(data => {
                    if (data.status) {
                        this.$message.success("收藏成功")
                    } else {
                        this.$message.error("收藏失败")
                    }
                })
            },
            // 借阅
            borrow(item,val) {
                if(val==1){
                    item.fileName= item.culturalName
                    item.exhibitionId =item.fieldName30
                }else{
                    this.dataListSelections.forEach(itemc =>{
                        itemc.fileName = itemc.culturalName
                        itemc.exhibitionId =itemc.fieldName30
                    })
                }
                console.log([item])
                let data =val==1? [item]:this.dataListSelections
                this.$refs.borrowForm.init(data)
            },
            // 下载
            // download(id) {
            //     let ids = id || this.dataListSelections.map(item => {
            //         return item.id
            //     }).join(',')
            // },
            addWatermark(url) {
                let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
                let sourceFile = this.$getFileTemporary+JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                // if (this.filePath.match(RegExp(/home/))) {
                //     sourceFile = url.substring(0, (url.indexOf("/")) + 1) + "home/fileTemporary/" + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                // } else {
                //     sourceFile = url.substring(0, (url.indexOf("\\")) + 1) + "fileTemporary\\" + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("\\"), url.length)
                // }
                return new Promise((resolve, reject) => {
                    this.$axios("zyd-catalogue/pdf/addWatermark", {
                        "target": url,
                        "sourceFile": sourceFile,
                        "watermark": watermark
                    }, 'post').then(data => {
                        if (data.status) {
                            resolve(data.data)
                        } else {
                            resolve("")
                        }
                    })
                })
            },
            // 下载
            download(row,val) {
                debugger
                if(val!=1){
                    console.log(this.dataListSelections)
                    this.dataListSelections.forEach(item =>{
                        this.addWatermark(item.url).then(data => {
                            let url = ""
                            if (data) {
                                // console.log("接口数据：",data);
                                url = this.$getUrl() + data.substring(data.indexOf("\\"), data.length)
                                // console.log("接口url:",this.fileDetailsForm.url);
                            } else {
                                url = item.url;  // 完整的url则直接使用
                                // this.fileDetailsForm.url= this.$getUrl()+this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("\\"),this.fileDetailsForm.url.length)
                            }
                            const a = document.createElement('a')
                            //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                            // 这里是将url转成blob地址，
                            fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                                a.href = URL.createObjectURL(blob)
                                // console.log(a.href)
                                a.download = item.culturalName // 下载文件的名字
                                // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                                document.body.appendChild(a)
                                a.click()
                            })
                        })
                    })
                }else{
                    this.addWatermark(row.url).then(data => {
                        let url = ""
                        if (data) {
                            // console.log("接口数据：",data);
                            url = this.$getUrl() + data.substring(data.indexOf("\\"), data.length)
                            // console.log("接口url:",this.fileDetailsForm.url);
                        } else {
                            url = row.url;  // 完整的url则直接使用
                            // this.fileDetailsForm.url= this.$getUrl()+this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("\\"),this.fileDetailsForm.url.length)
                        }
                        const a = document.createElement('a')
                        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                        // 这里是将url转成blob地址，
                        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                            a.href = URL.createObjectURL(blob)
                            // console.log(a.href)
                            a.download = row.culturalName // 下载文件的名字
                            // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                            document.body.appendChild(a)
                            a.click()
                        })
                    })
                }
            },
            // 查看
            view(row) {
                this.$refs.detailPage.init(row.id, 'culturalList')
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getDataList(this.advanced);

            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getDataList(this.advanced);

            },
        }
    }
</script>

<style scoped>

</style>
